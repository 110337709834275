.aboutUs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  font-family: bankGothlic;
  color: black;
  padding: 2rem 0 5rem 0;
  overflow: hidden;
}

.aboutUs h1 {
  margin: 1rem auto 3rem;
  text-align: left;
  width: min(72rem, 98%);
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.25rem;
  font-size: 2rem;
  /* background-color: bisque; */
  padding: 0.5rem 2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.aboutUs h3 {
  font-family: "Cormorant Infant", serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  margin-left: 1.5rem;
  padding: 0;
}

.about_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: min(72rem, 98%);
  margin: auto 0;
  gap: 2rem;
  align-items: center;
  align-content: center;
}

.hiddenContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: min(72rem, 98%);
  gap: 2rem;
  margin-top: 2rem;
}

.RightImg img {
  width: 20rem;
  height: 15rem;
  object-fit: cover;
  object-position: 50% 40%;
  border-radius: 8px;
}

.RightImg .img-1 {
  animation: aboutInfo 1s ease-in-out forwards;
}

.RightImg .img-2 {
  animation: aboutInfo2 1s ease-in-out forwards;
  height: 28rem;
}

@keyframes aboutInfo {
  0% {
    position: relative;
    right: -50%;
    opacity: 0;
  }

  100% {
    position: relative;
    right: 0%;
    opacity: 1;
  }
}

@keyframes aboutInfo2 {
  0% {
    position: relative;
    left: -50%;
    opacity: 0;
  }

  100% {
    position: relative;
    left: 0%;
    opacity: 1;
  }
}

@keyframes dropDown {
  0%,
  100% {
    position: relative;
    top: -3rem;
  }

  50% {
    position: relative;
    top: -3.5rem;
  }
}

@media screen and (max-width: 650px) {
  .aboutUs {
    text-align: justify;
    text-justify: newspaper;
    width: 100%;
    text-align: center;
    position: relative;
    top: -3rem;
  }

  .about_container,
  .hiddenContainer {
    gap: 0.5rem;
    width: 95%;
  }

  .aboutUs h1 {
    font-size: 22px;
  }

  .leftInfo h3 {
    font-size: 18px;
  }

  .leftInfo p {
    font-size: 12px;
  }

  .RightImg img {
    display: none;
  }
}
