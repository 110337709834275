.sponsor_wrapper {
  background-color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  margin-bottom: 2rem;
  color: black;
  padding: 2rem;
  padding-bottom: 8rem;
}

.sponsor_wrapper h1 {
  text-align: left;
  width: min(72rem, 98%);
  margin: 0 auto;
  display: flex;
  align-items: center;
  letter-spacing: 0.2rem;
  border-top: solid 1px black;

  border-bottom: solid 1px black;
  padding: 1rem;
}
.sponsor_wrapper h3 {
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.goldImg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 3rem;
  flex-wrap: wrap;

  margin-bottom: 2rem;
}

.softImg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  /* width: 100vw; */
}

.goldImg img {
  width: auto;
  height: 5rem;
}

.softImg img {
  width: auto;
  height: 5rem;
}

@media screen and (max-width: 650px) {
  .sponsor_wrapper {
    text-align: left;
  }

  .softImg img {
    width: 7rem;
    height: 3rem;
  }

  .goldImg img {
    height: 5rem;
    width: 10rem;
  }

  .goldsponsor h3,
  .softsponsor h3 {
    margin-left: 1rem;
  }
  .goldImg {
    flex-direction: column;
  }
  .softImg {
    flex-direction: column;
  }
  .team_wrapper h1 {
    color: white;
    letter-spacing: 0.2rem;
  }
}
