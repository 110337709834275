.contactUs {
    width: 98%;
    height: 150px;
    background-color: black;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-left: 1rem;
    text-align: center;
    padding-bottom: 2rem;
    color: white;
}

.contactUs h1 {
    text-align: center;
}

.emaPhn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 1rem;
}

.socialMedia button {
    background: transparent;
    border: none;
    font-size: 26px;
    cursor: pointer;
}

@media screen and (max-width:650px) {
    .emaPhn {
        font-size: 14px;
    }

    .socialMedia button {
        font-size: 24px;
    }
}