.gallery_container {
  width: 100%;
  background-color: white;
}
.gallery_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-top-right-radius: 20%;
  border-top-left-radius: 20%;
  background-color: black;
}
.gallery_wrapper h1 {
  margin-top: 4rem;
  letter-spacing: 0.2rem;
  display: flex;
  align-items: center;
  /* margin-bottom: 2rem; */
}
.gallery_wrapper h1 img {
  width: 50px;
  margin-right: 1.5rem;
}

/* .imgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 2rem;
} */

.imgs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: center;
  align-content: center;
  padding: 1rem;
  width: min(72rem, 98%);
  margin: 2rem auto;
}
.imgs > div {
  background-color: white;
  padding: 1rem 1rem 5rem 1rem;
  border: 1px solid black;
}
.imgs img {
  width: 100%;
  height: 17rem;
  object-fit: cover;
}

@media screen and (max-width: 650px) {
  .gallery_wrapper {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .imgs img {
    width: 9rem;
    height: 9rem;
  }
  /* .imgs > div {
    padding: 0.5rem 0.5rem 3rem 0.5rem;
  } */
  .imgs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;
    align-content: center;
    padding: 0.5rem;
    width: min(72rem, 98%);
    margin: 2rem auto;
  }
  .imgs > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 1rem 0.5rem 3rem 0.5rem;
    border: 1px solid black;
  }
}
