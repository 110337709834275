@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Maitree:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Maitree:wght@200;300;400;500;600;700&family=Palanquin+Dark:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text:wght@100..900&display=swap");
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
  overflow-x: hidden;
}

.palanquin-dark-semibold {
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.maitree-medium {
  font-family: "Maitree", serif;
  font-weight: 500;
  font-style: normal;
}
.cormorant-infant-regular {
  font-family: "Cormorant Infant", serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Bebas;
  src: url("../public/BebasNeue-Regular.ttf");
}

@font-face {
  font-family: Lato;
  src: url("../public/Lato-Regular.ttf");
}

@font-face {
  font-family: montserrat;
  src: url("../public/MontserratAlt1-ExtraLight.ttf");
}

h1,
h2 {
  font-family: Bebas;
  font-weight: bolder;
}

p {
  font-family: Lato;
}

@media screen and (max-width: 650px) {
  h1 {
    font-size: 24px;
  }
}
